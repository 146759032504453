import { Text, type TextProps } from '../text';

export type ListItemTitleProps = TextProps;

export function ListItemTitle({
  weight = 400,
  lineClamp = 3,
  ...rest
}: ListItemTitleProps) {
  return (
    <Text component='h3' {...rest} weight={weight} lineClamp={lineClamp} />
  );
}
