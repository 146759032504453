import { twMerge } from 'tailwind-merge';
import { Text, type TextProps } from '../text';

export type ListItemMetaProps = TextProps;

export function ListItemMeta({ className, ...rest }: ListItemMetaProps) {
  return (
    <Text
      className={twMerge('text-gray-400 text-[13px] mt-1', className)}
      {...rest}
    />
  );
}
