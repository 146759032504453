import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type VariantProps, cva } from 'class-variance-authority';
import type { PropsWithChildren } from 'react';

const alert = cva(
  [
    // 'p-4', 'lg:py-6', 'xl:py-8', 'rounded-xl',
    'border',
    'flex',
    'items-start',
  ],
  {
    variants: {
      type: {
        default: ['bg-gray-50 border-gray-200 text-gray-600'],
        info: ['bg-sky-50', 'border-sky-100', 'text-sky-950'],
        warning: ['bg-amber-50', 'border-amber-100', 'text-amber-950'],
        error: ['bg-red-50', 'border-red-100', 'text-red-950'],
        success: ['bg-green-50', 'border-green-100', 'text-green-950'],
      },
      size: {
        xs: ['p-2', 'text-sm', 'rounded-md'],
        small: ['px-2', 'py-3', 'rounded-lg'],
        medium: ['p-4', 'rounded-lg'],
        large: ['px-4', 'py-6', 'rounded-xl'],
        xl: ['px-4', 'py-8', 'rounded-xl'],
      },
    },
    defaultVariants: {
      type: 'default',
      size: 'medium',
    },
  },
);

const alertTitle = cva(['mb-2', 'font-semibold'], {
  variants: {
    size: {
      xs: ['text-sm'],
      small: ['text-base'],
      medium: ['text-base'],
      large: ['text-base'],
      xl: ['text-base'],
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export interface AlertProps
  extends PropsWithChildren,
    VariantProps<typeof alert> {
  title?: string;
  className?: string;
}

const getIcon = (type: Pick<VariantProps<typeof alert>, 'type'>['type']) => {
  switch (type) {
    case 'warning':
      return faExclamationTriangle;
    case 'error':
      return faExclamationCircle;
    case 'success':
      return faCheckCircle;
    case 'info':
      return faCircleInfo;
    default:
      return faCircleQuestion;
  }
};

export function Alert({ children, type, size, title, className }: AlertProps) {
  return (
    <div className={alert({ type, size, className })} role='alert'>
      <div className='flex mr-3 justify-center items-center w-6 h-6'>
        <FontAwesomeIcon icon={getIcon(type)} />
      </div>
      <div className='flex flex-col flex-1'>
        {title && <div className={alertTitle({ size })}>{title}</div>}
        {children}
      </div>
    </div>
  );
}
